var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',_vm._b({attrs:{"label":"Rut","hint":"Rut debe tener mínimo 8 caracteres","loading":_vm.rutLoading || _vm.loading,"placeholder":"Escribe...","required":_vm.required,"disabled":_vm.disabled|| _vm.disabledField,"rules":_vm.rutRules,"g-mask":{
        mask: '########',
        tokens: {
          '#': { pattern: /[0-9]/ },
        },
        kind:'both'
      }},on:{"blur":_vm.validateRut},model:{value:(_vm.rutNumber),callback:function ($$v) {_vm.rutNumber=$$v},expression:"rutNumber"}},'v-text-field',_vm.$attrs,false))],1),_c('v-col',{staticClass:"text-center dash-col mb-6",attrs:{"cols":"1"}},[_c('span',{staticClass:"dash"},[_vm._v("-")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"verifier-field",attrs:{"label":"DV","readonly":"","disabled":""},model:{value:(_vm.verifierDigit),callback:function ($$v) {_vm.verifierDigit=$$v},expression:"verifierDigit"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }