
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { fixAmountFormatted, isValidNumber } from '@/utils/general'
  import { GForm } from '@/components/forms/GForm'
  import { plainToInstance } from 'class-transformer'
  import { Employee } from '@/entities/hr'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { PersonFormatted } from '@/store/persons/person'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { Person } from '@/entities/persons'
  import { ConsultRutInterface } from '@/store/persons/state'
  import LaborDataDependent from '@/components/person/laborData/LaborDataDependent.vue'
  import { User } from '@/entities/public'
  import GRut from '@/components/core/input/GRut.vue'
  import GPhone from '@/components/core/input/GPhone.vue'

interface RegisterUserInput {
  displayName: string;
  email: string;
  role: string[];
  password: string;
}

interface ChangeDefaultRoleInput {
  email: string;
  defaultRole: string;
}

interface ChangeAllowedRolesInput {
  email: string;
  allowedRoles: string[];
}

@Component({
  components: { GPhone, GRut, LaborDataDependent, GAlert, GFiles, BaseForm },
  methods: {
    ...mapActions('user', ['registerUser', 'changeDefaultRole', 'changeAllowedRoles']),
    ...mapMutations('persons', ['setFoundPerson']),
    ...mapActions('notification', ['loadNotification']),
    fixAmountFormatted,
  },
  computed: {
    ...mapGetters('persons', ['getFoundPerson', 'rutInfo']),
  },
})
  export default class SysopEmployeeForm extends GForm {
  getFoundPerson!: PersonFormatted | null;
  rutInfo!: ConsultRutInterface
  setFoundPerson!: (person: PersonFormatted | null) => void;
  registerUser!: (data: RegisterUserInput) => Promise<any>;
  changeDefaultRole!: (data: ChangeDefaultRoleInput) => Promise<any>;
  changeAllowedRoles!: (data: ChangeAllowedRolesInput) => Promise<any>;
  employee = plainToInstance(Employee, {})
  person = plainToInstance(Person, {})
  userData = plainToInstance(User, {})
  title = ''
  metadata = {}
  showDetail = false
  displayError = false
  idProcess = null
  alert = {
    open: false,
    title: '',
    subtitle: '',
  }

  declare $refs: {
    form: HTMLFormElement
    phone: Vue & { $refs: { form: Vue & { validate: () => boolean } } };
  };

  systemRoles = []
  userRoles = []
  formData = {
    rut: '',
    firstName: '',
    secondName: '',
    lastName: '',
    secondLastName: '',
    phone: null,
    email: '',
    photo: [],
    roles: [],
  }

  fields = {
    photo: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: 'string',
        label: 'string',
        required: false,
        icon: 'string',
      },
    },
  }

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()

    const { id, uid, title, metadata } = this

    if (isValidNumber(id) || isValidNumber(uid)) {
      const employeeId = id || uid
      this.title = 'EDITAR ROL'
      await this.getEmployeeInfo(employeeId)
    } else {
      this.title = 'CREAR INTEGRANTE'
    }
    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(this.employee?.id))
    }
    this.loadingForm = false
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)

    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('Employee', 'sysop_employee')

    this.metadata = metadata
    this.systemRoles = await this.fetchData({
      query: { name: 'find', model: 'Rol', order: { id: 'asc' } },
      filter: { slug: { _nin: ['saleor', 'financer', 'coo', 'sysop', 'auditor'] } },
    })

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'person' } },
    })

    this.idProcess = process[0].id

    const file = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'person' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    this.setProperties(file, 'photo', 'photo')
  }

  async getEmployeeInfo (id) {
    this.employee.id = id
    const person = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { employee: { id: { _eq: id } } },
      force: true,
    })
    this.employee.person = person[0]
    const user = await this.fetchData({
      query: { name: 'find', model: 'User' },
      filter: { person: { id: { _eq: person[0].id } } },
      force: true,
    })
    this.userData = user[0]
    const roles = await this.fetchData({
      query: { name: 'find', model: 'Roles' },
      filter: { user: { id: { _eq: user[0].id } } },
      force: true,
    })
    this.formData.roles = roles.map(role => role.rol.slug)
    this.userRoles = roles
    this.formData.photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: person[0]?.id } },
          { parameter: { process: { id: { _eq: this.idProcess } } } },
        ],
      },
      force: true,
    })
  }

  async send () {
    const phone = this.$refs.phone
    const isEmptyRole = this.formData.roles.length === 0

    if (!phone?.$refs.form.validate() || !this.$refs.form.validate() || isEmptyRole) {
      await this.setFieldError()
      this.displayError = true
      return
    }
    this.loadingForm = true
    const { employee } = this

    if (!employee?.id) {
      await this.createUser()
    } else {
      await this.editUser()
    }

    this.loadingForm = false
    await this.close()
  }

  async editUser () {
    const { formData, systemRoles, employee, userRoles, userData } = this

    if (formData?.photo?.length) {
      await this.handleFileType(formData.photo, { properties: this.fields.photo.properties }, this.idProcess, employee.person.id)
    }

    const rolesToAdd = systemRoles.filter(role => formData.roles.includes(role.slug) && !userRoles.some(userRole => userRole.rol.slug === role.slug))
    const rolesToRemove = userRoles.filter(userRole => !formData.roles.includes(userRole.rol.slug))

    if (rolesToRemove?.length) {
      await Promise.all(rolesToRemove.map(async role => {
        await this.removeData({
          model: 'Roles',
          fields: {
            id: role.id,
          },
        })
      }))
    }

    if (rolesToAdd?.length) {
      await Promise.all(rolesToAdd.map(async role => {
        await this.pushData({
          model: 'Roles',
          fields: {
            userId: userData.id,
            roleId: role.id,
          },
        })
      }))
    }

    await this.changeDefaultRole({ email: employee.person.email.work, defaultRole: formData.roles[0] })
    await this.changeAllowedRoles({ email: employee.person.email.work, allowedRoles: formData.roles })
  }

  async createUser () {
    const { formData, systemRoles, person } = this

    let personId = person.id
    if (!personId) {
      const personCreated = await this.pushData({
        model: 'Person',
        fields: {
          first_name: formData.firstName.toUpperCase(),
          second_name: formData.secondName.toUpperCase(),
          surname: formData.lastName.toUpperCase(),
          second_surname: formData.secondLastName.toUpperCase(),
          secondary_phone: { work: formData.phone },
          uid: formData.rut,
          email: { work: formData.email.toLowerCase() },
        },
      })
      personId = personCreated.id
    } else {
      await this.pushData({
        model: 'Person',
        fields: {
          id: personId,
          first_name: formData.firstName.toUpperCase(),
          second_name: formData.secondName.toUpperCase(),
          surname: formData.lastName.toUpperCase(),
          second_surname: formData.secondLastName.toUpperCase(),
          secondary_phone: { work: formData.phone },
          email: { work: formData.email.toLowerCase(), personal: this.person.email?.personal.toLowerCase() },
        },
      })
    }

    if (this.formData?.photo?.length) {
      await this.handleFileType(this.formData.photo, { properties: this.fields.photo.properties }, this.idProcess, personId)
    }

    const existEmployee = await this.fetchData({
      query: { name: 'find', model: 'Employee' },
      filter: { person: { id: { _eq: personId } } },
    })
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'employee' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    if (!existEmployee?.length) {
      await this.pushData({
        model: 'Employee',
        fields: {
          id_person: personId,
          id_process_status: status[0].id,
        },
      })
    } else {
      await this.pushData({
        model: 'Employee',
        fields: {
          id: existEmployee[0].id,
          id_process_status: status[0].id,
        },
      })
    }

    await this.pushData({
      model: 'User',
      fields: {
        id_person: personId,
        email: formData.email.toLowerCase(),
        roles: {
          data: systemRoles.filter(rol => formData.roles.includes(rol.slug)).map(role => ({ roleId: role.id })),
        },
      },
    })

    await this.registerUser({
      displayName: `${formData.firstName} ${formData.lastName}`,
      email: formData.email.toLowerCase(),
      role: formData.roles,
      password: `${formData.firstName[0].toUpperCase()}${formData.lastName[0].toUpperCase()}123456`,
    })
  }

  validateRut (rut: string): void {
    if (!this.loadingForm) {
      this.findPerson({ phone: null, rut, id: null })
    }
  }

  async checkEmail (email: string) {
    if (!email?.length) return

    const users = await this.fetchData({
      query: { name: 'findLite', model: 'User' },
      filter: { email: { _eq: email } },
    })

    if (users?.length) {
      await this.loadNotification({
        message: `Ya existe un usuario con el email ${email}`,
        notificationType: 'error',
      })
      this.formData.email = ''
    }
  }

  confirmSetRutData () {
    const { getFoundPerson, rutInfo } = this
    this.alert.open = false
    this.alert.title = ''
    this.alert.subtitle = ''

    if (getFoundPerson.id) {
      this.formData.firstName = getFoundPerson.name
      this.formData.secondName = getFoundPerson.secondName
      this.formData.lastName = getFoundPerson.surname
      this.formData.secondLastName = getFoundPerson.secondSurname
      this.formData.phone = getFoundPerson.phoneWork
      this.formData.email = getFoundPerson.emailWork
      this.person.id = getFoundPerson.id
    } else if (rutInfo.firstName?.length) {
      this.formData.firstName = rutInfo.firstName
      this.formData.secondName = rutInfo.secondName
      this.formData.lastName = rutInfo.firstLastName
      this.formData.secondLastName = rutInfo.secondLastName
    }
  }

  closeRutInfo () {
    this.alert.open = false
    this.alert.title = ''
    this.alert.subtitle = ''
    this.formData.rut = ''
    this.formData.firstName = ''
    this.formData.secondName = ''
    this.formData.lastName = ''
    this.formData.secondLastName = ''
    this.formData.phone = ''
    this.formData.email = ''
    this.formData.photo = []
    this.setRutInfo(undefined)
    this.setFoundPerson(null)
  }

  updateUser () {
    console.log('update')
  }

  closeForm () {
    this.closeRutInfo()
    this.close(this.formData.photo)
  }

  get change () {
    const { formData } = this

    return JSON.stringify(formData)
  }

  get displayName () {
    const { employee } = this

    return Boolean(employee?.id) && Boolean(employee?.person?.id)
  }

  @Watch('formData.roles', { immediate: true, deep: true })
  onRolesChange (val) {
    if (this.displayError) {
      this.displayError = Boolean(!val.length)
    }
  }

  @Watch('getFoundPerson', { immediate: true, deep: true })
  onFoundPersonChange (val) {
    if (val) {
      this.formData.rut = val.rut
      this.alert.open = true
      this.alert.title = `Según nuestro sistema, el rut ${this.formData.rut} corresponde a:`
      this.alert.subtitle = `${val.name} ${val.secondName} ${val.surname} ${val.secondSurname}`
    }
  }

  @Watch('rutInfo', { immediate: true, deep: true })
  onRutInfoChange (val) {
    if (val) {
      this.formData.rut = val.rut
      if (val.firstName?.length) {
        this.alert.open = true
        this.alert.title = `Según nuestro sistema, el rut ${this.formData.rut} corresponde a:`
        this.alert.subtitle = `${val.firstName} ${val.secondName} ${val.firstLastName} ${val.secondLastName}`
      }
    }
  }

  beforeDestroy () {
    this.closeRutInfo()
  }
  }
